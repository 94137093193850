<template>
    <div class="popup">
        <div class="box">
            <div class="popTitle popTitleArea">
                <p>{{ $t('page.patient.detail.button.transfer.request') }}</p>
                <div class="buttonArea">
                    <button class="x" @click="closePopup(false)"><i class="micon">close</i></button>
                </div>
            </div>
            <div class="popupContentArea">
                <ul class="cont">
                    <li>
                        <div class="titArea">
                            <p class="tit">{{ $t('page.transfer.popup.subTitle.selectUser') }}</p>
                        </div>
                        <UserListCompo :isAdmin="false" @setTransfeeNo="setTransfeeNo" :key="userListKey" />
                    </li>
                    <li>
                        <div class="titArea">
                            <p class="tit">{{ $t('page.transfer.popup.subTitle.regNote') }} *</p>
                        </div>
                        <div>
                            <v-textarea flat solo density="compact" hide-details="auto" v-model="comment"></v-textarea>
                        </div>
                    </li>
                </ul>
                <div class="btnW popBtn">
                    <button class="btn default" @click="closePopup(false)" v-ripple>{{$t("common.button.cancel")}}</button>
                    <button class="btn solid" @click="transferRequestProgress()" v-ripple>{{ $t('page.transfer.popup.button.regist') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API_USERS from '@/API/user/list';
import API_REGIST_REQUEST from '@/API/transferRequest/registRequest'
export default {
    components: {
    },
    props: {
        requestNo: {
            default: null
        },
        patientNo: {
            default: null
        }
    },
    data() {
        return {
            transfereeNo: null,
            isGetingItems: false,
            APIList: {
                APIReq: {
                    pageNo: 1,
                    pageSize: 100,
                },
                resultList: [],
                total: 0,
            },
            userMaxCnt: process.env.VUE_APP_USER_MAX_CNT,
            activeUserCnt: 0,
            comment: '',
            userListKey: ''
        };
    },
    watch: {},
    created() {},
    mounted() {
        this.setTransfeeNo(null)
        this.getList();
    },
    destroyed() {},
    methods: {
        closePopup(progress) {
            this.$emit('closePopup', progress);
            this.setTransfeeNo(null)
            this.userListKey += 1;
        },
        setTransfeeNo(no){
            this.transfereeNo = no;
        },
        async getList() {
            if (this.isGetingItems) {
                return;
            }
            this.APIList.resultList=[];
            this.isGetingItems = true;
            let res = await API_USERS.request(this.APIList.APIReq.pageNo,this.APIList.APIReq.pageSize, this.isAdmin);
            if (res.isSuccess) {
                this.APIList.resultList = res.userList;
                this.APIList.total = res.total;
                this.activeUserCnt = res.activeUserCnt;
                localStorage.setItem("activeUserCnt", this.activeUserCnt);
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGetingItems = false;
        },
        async transferRequestProgress(){
            if(!this.transfereeNo) {
                this.showPopup(this.$t('page.transfer.popup.msg.selectUser'))
                return;
            }
            if(!this.comment || this.comment === '') {
                this.showPopup(this.$t('page.transfer.popup.msg.fillReqNote'))
                return;
            }
            let res = await API_REGIST_REQUEST.request(this.patientNo, this.transfereeNo, 'REQST', this.comment);
            if(res.isSuccess){
                this.showPopup(this.$t('page.transfer.popup.msg.reqSuccess'))
                this.closePopup(false);
                this.$emit('checkRequestNo', res.data.no);
            }else{
                this.showPopup(res.errorMsg, res.status);
            }
        },
        initValue(){
            this.transfereeNo = null;
            this.comment = '';
        }
    },
    computed:{},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.popup {
    .box{
        max-height: 95vh;
        .x{position: static;}
        .popTitle{
            display:flex; justify-content: space-between; align-items: center;
            p{
                display:flex; justify-content: flex-start; align-items: center;
                i{margin-right: 8px;}
            }
        }
        .cont{
            >li{
                margin-bottom: 25px;
                &:last-child{margin-bottom: 0;}
            }
        }
    }
}
.borderWrap{border: 1px solid #dbdbdb;}
.tblScroll{
    max-height: 250px;
    border-radius: 4px;
    .tbl{
        tr:hover{cursor: pointer;}
        border-top:0;
        border-bottom: 0;
        thead th{font-size: 14px; padding: 12px;}
        td{font-size: 14px; padding: 12px; height: auto;}
    }
}
::v-deep .lay-root.thema-default{
    background-color: var(--color-point) !important;
    transition:box-shadow 0.5s,opacity 0.5s;
    &:hover{
        opacity: 0.85; box-shadow:0 4px 7px rgba(0,0,0,0.1);
    }
}

::v-deep .v-input__slot .v-text-field__slot textarea{padding: 10px !important;}
.infoBox{margin-bottom:20px;}
.infoBox ul{padding: 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; margin-bottom: 20px;}
.infoBox ul li{color:#111; padding: 0 0 4px 8px; font-size: 14px;}
.infoBox p{text-align: center; font-size: 15px; line-height: 2rem; font-weight: bold;}

</style>