var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "popTitle popTitleArea"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('page.patient.detail.button.transfer.request')))]), _c('div', {
    staticClass: "buttonArea"
  }, [_c('button', {
    staticClass: "x",
    on: {
      "click": function ($event) {
        return _vm.closePopup(false);
      }
    }
  }, [_c('i', {
    staticClass: "micon"
  }, [_vm._v("close")])])])]), _c('div', {
    staticClass: "popupContentArea"
  }, [_c('ul', {
    staticClass: "cont"
  }, [_c('li', [_c('div', {
    staticClass: "titArea"
  }, [_c('p', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$t('page.transfer.popup.subTitle.selectUser')))])]), _c('UserListCompo', {
    key: _vm.userListKey,
    attrs: {
      "isAdmin": false
    },
    on: {
      "setTransfeeNo": _vm.setTransfeeNo
    }
  })], 1), _c('li', [_c('div', {
    staticClass: "titArea"
  }, [_c('p', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$t('page.transfer.popup.subTitle.regNote')) + " *")])]), _c('div', [_c('v-textarea', {
    attrs: {
      "flat": "",
      "solo": "",
      "density": "compact",
      "hide-details": "auto"
    },
    model: {
      value: _vm.comment,
      callback: function ($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1)])]), _c('div', {
    staticClass: "btnW popBtn"
  }, [_c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn default",
    on: {
      "click": function ($event) {
        return _vm.closePopup(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn solid",
    on: {
      "click": function ($event) {
        return _vm.transferRequestProgress();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('page.transfer.popup.button.regist')))])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }